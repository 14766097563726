<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>角色管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="toolbar">
            <Button type="primary" @click="addRole" icon="md-add" v-if="permissionButton.add">添加角色</Button>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem label="角色名称" :label-width="75">
                    <Input v-model="filter.roleName" placeholder="请输入" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearch">查询</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="roleList" :loading="listLoading">
                    <template slot-scope="{ row, index }" slot="action">
                        <!-- <Button type="text" size="small" v-if="row.isReadonly!==1" @click="handleEditRole(row, index)" ghost>修改</Button> -->
                        <a v-if="row.roleId!==1&&permissionButton.edit&&row.isReadonly!==1"
                           @click="handleEditRole(row, index)"
                           ghost>编辑</a>
                        <Divider type="vertical" v-if="permissionButton.edit&&row.isReadonly!==1"/>
                        <a v-if="row.roleId!==1&&permissionButton.del&&row.isReadonly!==1"
                           @click="deleteRole(row, index)"
                           ghost>删除</a>
                    </template>
                </Table>
                <!-- Functional operation -->
                <addRole v-model="addShow" v-on:getRoles="getRoles"></addRole>
                <editRole v-model="updateUserShow" :roleId="roleId" v-on:getRoles="getRoles"></editRole>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ current }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :total="total" :page-size="pageSize" :current="current" @on-change="changePage"
                              @on-page-size-change="changPageSize" :page-size-opts="[15,20,50,100]" show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import {reqGetRoleListPage, reqDeleteRole} from "../../../api/system-api";
import permission from '../../../common/permission';
import addRole from '../role/add'
import editRole from '../role/edit';

export default {
    name: 'TableView',
    components: {
        addRole,
        editRole
    },
    data() {
        return {
            filter: {
                roleName: ''
            },
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    key: 'index',
                    width: 80
                },
                {
                    title: '角色名称',
                    key: 'roleName',
                    width: 300
                },
                {
                    title: '描述',
                    key: 'description'
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150
                }
            ],
            listLoading: true,
            addShow: false,
            updateUserShow: false,
            roleId: null,
            total: 0,
            pageNum: 1,
            pageSize: 20,
            pages: 1,
            current: 0,
            roleList: [],
            permissionButton: {
                add: false,
                edit: false,
                del: false,
            }
        }
    },
    methods: {
        //添加角色
        addRole() {
            this.addShow = true;
        },
        changePage(val) {
            this.pageNum = val;
            this.getRoles();
        },
        //每页显示的数据条数
        changPageSize(index) {
            //实时获取当前需要显示的条数
            this.pageSize = index;
            this.getRoles();
        },
        doSearch() {
            this.pageNum = 1;
            this.getRoles();
        },
        getRoles() {
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: this.pageSize,
                roleName: this.filter.roleName
            };
            that.roleList = [];
            reqGetRoleListPage(para).then((res) => {
                that.listLoading = false;
                if (res.data.code === 1) {
                    that.total = res.data.data.total;
                    that.pages = res.data.data.pages;
                    that.current = res.data.data.current;
                    that.roleList = res.data.data.records;
                }
            }).catch(() => {
                that.listLoading = false;
            })
        },
        //删除角色
        deleteRole(row, index) {
            this.$Modal.confirm({
                title: ' ',
                content: '确认删除该角色？',
                width: '240',
                onOk: () => {
                    let para = {roleId: row.roleId};
                    reqDeleteRole(para).then(res => {
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            // 删除数据成功同时刷新grid
                            this.getRoles();
                        } else {
                            this.$Message.warning(res.data.msg);
                        }
                    });
                },
            });
        },
        //编辑角色
        handleEditRole(row, index) {
            this.roleId = row.roleId;
            this.updateUserShow = true;
        },
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton);
        this.getRoles();
    }
}
</script>