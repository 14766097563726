<template>
    <Modal v-model="show" title="新增角色" @on-ok="submit" :loading="loading" :mask-closable="false">
        <Form ref="buttonForm" :model="buttonForm" :rules="buttonFormRule" label-colon :label-width="90">
            <FormItem label="角色名称" prop="roleName">
                <Input type="text" show-word-limit :maxlength="50" v-model.trim="buttonForm.roleName"
                       placeholder="请输入"/>
            </FormItem>
            <FormItem label="描述" prop="description">
                <Input type="textarea" show-word-limit :maxlength="500" v-model.trim="buttonForm.description"
                       :autosize="{minRows: 3, maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
import {reqAddRole} from '../../../api/system-api'

export default {
    name: "addRole",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            show: this.value,
            loading: true,
            buttonForm: {
                roleName: null,
                description: null,
            },
            buttonFormRule: this.getButtonFormRule()
        }
    },
    methods: {
        submit() {
            this.$refs['buttonForm'].validate((valid) => {
                if (valid) {
                    reqAddRole(this.buttonForm).then(res => {
                        if (res.data.code == 1) {
                            this.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            this.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            this.$emit('getRoles');
                        } else {
                            this.$Message.error(res.data.msg);
                        }
                    })
                }
                setTimeout(() => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.loading = true;
                    });
                }, 1000);
            });
        },
        getButtonFormRule() {
            return {
                roleName: [
                    {required: true, message: '请输入角色名称', trigger: 'blur'},
                ]
            }
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.show = val;
        },
        show(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['buttonForm'].resetFields();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>